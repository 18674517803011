import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout'
import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import { getToken } from '@/utils/auth'
import store from '@/store'
import { getInfo } from '@/api/info'

Vue.use(VueRouter)

const routes = [
  {
    component: Layout,
    redirect: '/home',
    path: '/',
    children: [
      {
        path: 'home',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Home'),
        name: 'home'
      },
      {
        path: 'StationMap',
        component: (resolve) => require(['@/views/StationMap'], resolve),
        name: 'stationMap'
      },
      {
        path: 'MeterReading',
        component: (resolve) => require(['@/views/MeterReading'], resolve),
        name: 'MeterReading'
      },
      {
        path: 'StationDetails',
        component: (resolve) => require(['@/views/StationDetails'], resolve),
        name: 'StationDetails'
      },
      {
        path: 'Business/User',
        component: (resolve) => require(['@/views/Business/User'], resolve),
        name: 'BusinessUser'
      },
      {
        path: 'DeviceInfo',
        component: (resolve) => require(['@/views/DeviceInfo'], resolve),
        name: 'DeviceInfo'
      },
      {
        path: 'HistoryAnalysis',
        component: (resolve) => require(['@/views/HistoryAnalysis'], resolve),
        name: 'HistoryAnalysis'
      }
    ]
  },
  {
    path: '/ScreenDisplayBase',
    component: (resolve) => require(['@/views/ScreenDisplay'], resolve),
    name: 'ScreenDisplayBase',
    children:[
      {
        path: '/ScreenDisplay',
        name: 'index',
        component: () => import(/* webpackChunkName: "LSD.bighome" */ '@/views/ScreenDisplay/indexs'),
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: (resolve) => require(['@/views/Login'], resolve),
    hidden: true
  },
  {
    path: '*',
    name: '*',
    component: (resolve) => require(['@/views/404'], resolve),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

NProgress.configure({ showSpinner: false })

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  if (to.matched.length == 0){
    next({path: '/404'})
  }
  NProgress.start()
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      // 修改appbar-title 内容
      // store.dispatch('layout/changeTitle', {appBarTitle: to.meta.title})
      // 设置用户信息 公司信息
      if (store.state.info.userInfo == null) {
        await getInfo().then(res => {
          store.dispatch('info/setUserInfo', {
            userInfo: res.data.user,
          })
          store.dispatch('info/setCompanyInfo', {
            companyInfo: res.data.company,
          })
        })
      }
      next()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})


export default router
