import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'
import dataV from '@jiaminghi/data-view'
import Echart from './components/echart/index.vue'

import ItemWrap from './components/item-wrap/item-wrap.vue'


Vue.config.productionTip = false
Vue.use(Antd)
// datav组件
Vue.use(dataV)

// 自定义组件
Vue.component("ItemWrap", ItemWrap)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Echart", Echart)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
