import axios from 'axios'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import qs from 'qs'
import { notification } from 'ant-design-vue'
import router from '@/router'
import { removeToken } from '@/utils/auth'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_API_HOST,
  // 超时
  // timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'jwt ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // 设置 get 方式 array 传递格式
  if (config.method === 'get') {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2,不转换会显示为arr[]=1&arr[]=2
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 0
    // 获取错误信息
    const msg = res.data.msg
    if (code === 1) {
      notification.warning({
        message: msg
      })
      return res.data
    } else {
      return res.data
    }
  },
  error => {
    let { message } = error
    try {
      const code = error.response.status
      const resp = error.response
      message = errorCode[code] || resp.data.msg || '系统接口' + resp.config.url + '异常' || errorCode['default']
      if(code == 401) {
        notification.error({
          message: message
        })
        removeToken()
        router.push('/login')
        return
      }
    } catch(err) {
      if (message == 'Network Error') {
        message = '后端接口连接异常'
      }
      else if (message.includes('timeout')) {
        message = '系统接口请求超时'
      } else {
        errorCode['default']
      }
    }
    notification.error({
      message: message
    })
    return Promise.reject(error)
  }
)

export default service
