<template>
  <a-layout id="components-layout-top-side-2" style="min-width: 350px">
    <!-- 头部内容 -->
    <a-layout-header style="padding: 0 50px 0 10px;" class="disabled">
      <!-- <div class="logo" /> -->
      <div style="float: right; color: #fff; padding-left: 16px; border-left: 1px solid rgba(255, 255, 255, 0.3);">
        <a-dropdown>
          <div>
            <a-avatar>
              <a-icon slot="icon" type="user" />
            </a-avatar>
            <span style="margin-left: 6px">{{ userInfo.name }}</span>
          </div>
          <a-menu slot="overlay">
            <a-menu-item key='1'> 账户信息管理</a-menu-item>
            <a-menu-item key='2' @click="exit"> 登出</a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div style="float: right; color: #fff; margin-right: 16px; padding-left: 16px; border-left: 1px solid rgba(255, 255, 255, 0.3);">
        <a-icon type="solution" style="margin-right: 3px;" /> 待办
      </div>
      <div style="float: right; color: #fff; margin-right: 16px;">
        <a-icon type="sound" style="margin-right: 3px;" /> 消息
      </div>  
    </a-layout-header>
    <a-layout>
      <!-- 菜单 -->
      <a-layout-sider width="200" style="background: #fff" :trigger="false" :collapsed="collapsed">
        <a-menu
          mode="inline"
          :style="{ height: '100%', borderRight: 0 }"
          :open-keys="openKeys"
          :selectedKeys="selectedKeys"
          @openChange="onOpenChange"
          @select="onSelectChange"
          class="disabled"
        >
          <template v-for="(item, index) in menu">
            <a-sub-menu :key="index" v-if="item.children.length">
              <span slot="title">
                <a-icon :type="item.icon" />
                <span v-show="!collapsed">{{ item.name }}</span>
              </span>
              <a-menu-item v-for="(subItem, subIndex) in item.children" :key="`${index}-${subIndex}`" :disabled="subItem.disabled">
                <span>
                  {{ subItem.name }}
                </span>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item v-else :key="`${index}`" :disabled="item.disabled">
              <a-icon :type="item.icon" />
              <span>
                {{ item.name }}
              </span>
            </a-menu-item>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout style="padding: 0 24px 24px">
        <!-- 面包屑导航 -->
          <a-breadcrumb style="margin: 10px 0">
            <a-button type="primary" style="margin-right: 16px" @click="toggleCollapsed">
              <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
            </a-button>
            <a-breadcrumb-item v-for="(item, index) in breadcrumbArr" :key="index">
              {{ item.name }}
            </a-breadcrumb-item>
          </a-breadcrumb>
        <!-- 内容区域 -->
        <a-layout-content :style="{ background: '#fff', padding: '6px', margin: 0, minHeight: '280px' }" id="content">
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
var mockMenu = [
  {
    "name": "数据应用",
    "path": "",
    "new": false,
    "hot": true,
    "icon": "dashboard",
    "children": [
      {
        "name": "数据概览",
        "path": "/home",
        "new": false,
        "hot": false,
        "icon": "",
        "children": []
      },
      {
        "name": "实时详情",
        "path": "/StationDetails",
        "new": false,
        "hot": false,
        "icon": "",
        "children": []
      },
      {
        "name": "历史分析",
        "path": "/HistoryAnalysis",
        "new": false,
        "hot": false,
        "icon": "",
        "children": [],
        "disabled": false
      },
      {
        "name": "手动补录",
        "path": "/MeterReading",
        "new": false,
        "hot": false,
        "icon": "",
        "children": []
      }
    ]
  },
  {
    "name": "地图查看",
    "path": "/StationMap",
    "new": false,
    "hot": false,
    "icon": "appstore",
    "children": []
  },
  {
    "name": "大屏仪表",
    "path": "/ScreenDisplay",
    "new": false,
    "hot": false,
    "icon": "desktop",
    "children": [],
    "disabled": false
  },
  {
    "name": "业务管理",
    "path": "",
    "new": false,
    "hot": false,
    "icon": "solution",
    "children": [
      // {
      //   "name": "工单",
      //   "path": "/ticket",
      //   "new": false,
      //   "hot": false,
      //   "icon": "",
      //   "children": [],
      //   "disabled": true
      // },
      {
        "name": "人员信息",
        "path": "/Business/User",
        "new": false,
        "hot": false,
        "icon": "",
        "children": [],
        "disabled": false
      }
    ]
  },
  {
    "name": "资产信息",
    "path": "/DeviceInfo",
    "new": false,
    "hot": false,
    "icon": "safety",
    "children": [],
    "disabled": false
  },
  // {
  //   "name": "缴费系统",
  //   "path": "",
  //   "new": false,
  //   "hot": false,
  //   "icon": "setting",
  //   "children": [],
  //   "disabled": true
  // }
]
import { removeToken } from '@/utils/auth'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Layout',
  data() {
    return {
      screenWidth: document.body.clientWidth,
      collapsed: false,
      menu: mockMenu,
      openKeys: [],
      selectedKeys: [],
      companyInfo: {},
      userInfo: {}
    }
  },
  mounted() {
    if (this.screenWidth < 500) {
      this.collapsed = true
    }
    const that = this
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth
      that.screenWidth = window.screenWidth
    }
    this.$nextTick(() => {
      // 设置用户信息 公司信息
      this.userInfo = this.$store.state.info.userInfo
      this.companyInfo = this.$store.state.info.companyInfo
    })
    // 连接直接进入页面设置菜单，不支持菜单中有重复路径
    this.init()
  },
  computed: {
    breadcrumbArr() {
      if (this.selectedKeys.length == 0) return []
      let tempArr = []
      const path = this.selectedKeys[0].split('-')
      tempArr.push(this.menu[path[0]])
      if (path.length == 2) {
        tempArr.push(this.menu[path[0]].children[path[1]])
      }
      return tempArr
    }
  },
  watch: {
    screenWidth(n) {
      if (n < 500) {
        this.closeCollapsed()
      }
    },
    '$route.path': function(){
        //console.log(newVal+"---"+oldVal);
        this.init()
      }
  },
  methods: {
    init() {
      this.menu.forEach(element => {
        if (element.path == this.$route.path) {
          this.selectedKeys = [`${this.menu.indexOf(element)}`]
          return
        }
        if (element.children.length) {
          element.children.forEach(subElement => {
            if (subElement.path == this.$route.path) {
              this.selectedKeys = [`${this.menu.indexOf(element)}-${element.children.indexOf(subElement)}`]
              this.openKeys = [this.menu.indexOf(element)]
              return
            }
          })
        }
      })
    },
    exit() {
      removeToken()
      this.$store.state.info.userInfo = null
      this.$router.push({path: '/login'})
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed
    },
    closeCollapsed() {
      this.$nextTick(() => {
        this.collapsed = true
      })
    },
    onOpenChange(openKeys) {
      if (openKeys.length == 0) {
        this.openKeys = []
        return
      }
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      this.openKeys = [latestOpenKey]
    },
    onSelectChange(item) {
      this.selectedKeys = [item.key]
      console.log(item)
      console.log(this.breadcrumbArr)
      console.log(this.breadcrumbArr.length)
      const r = this.breadcrumbArr[this.breadcrumbArr.length-1]
      console.log(r)
      this.$router.push(r.path, ()=>{}, ()=>{})
    }
  }
}
</script>

<style>
#components-layout-top-side-2 .logo {
  width: 240px;
  height: 43px;
  margin: 10px 0;
  float: left;
  position: relative;
  background: url('@/../public/logo.png') center / 80% no-repeat
}
#components-layout-top-side-2 {
  height: 100vh;
}
.disabled {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
</style>
