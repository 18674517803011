 const nav = {
  // 
  namespaced: true,
  state: {
    drawer: true,
    appBarTitle: '站房展示图'
  }, 
  // 逻辑处理,同步函数
  mutations: {
    TOGGLE(state, options) {
      state.drawer = options.drawer
    },
    CHANGETITLE(state, options) {
      state.appBarTitle = options.appBarTitle
    }
  },
  // 逻辑处理,异步函数
  actions :{
    toggle(context, options) {
      context.commit('TOGGLE', {
        drawer: options.drawer
      })
    },
    changeTitle(context, options) {
      context.commit('CHANGETITLE', {
        appBarTitle: options.appBarTitle
      })
    }
  }
}
export default nav