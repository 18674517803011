
/**
 * @param msg 信息
 * @param color snackbar 颜色
 * @param visible 是否可见
 * @param showClose 关闭按钮
 * @param timeout 停留持续时间 
 */
 const info = {
  // 
  namespaced: true,
  state: {
    userInfo: null,
    companyInfo: null,
    role: null
  }, 
  // 逻辑处理,同步函数
  mutations: {
    SET_USER_INFO(state, options) {
      state.userInfo = options.userInfo
    },
    SET_COMPANY_INFO(state, options) {
      state.companyInfo = options.companyInfo
    },
    SET_ROLE_INFO(state, options) {
      state.roleInfo = options.roleInfo
    },
  },
  // 逻辑处理,异步函数
  actions :{
    setUserInfo (context, options){
      context.commit('SET_USER_INFO',{
        userInfo: options.userInfo,
      })
    },
    setCompanyInfo (context, options){
      context.commit('SET_COMPANY_INFO',{
        companyInfo: options.companyInfo,
      })
    },
    setRoleInfo (context, options){
      context.commit('SET_ROLE_INFO',{
        roleInfo: options.roleInfo,
      })
    },
  }
}
export default info