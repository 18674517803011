<template>
  <div id="app">
    <a-locale-provider :locale="zh_CN">
      <router-view />
    </a-locale-provider>
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: 'app',
  components: {},
  data() {
    return {
      zh_CN
    }
  }
}
</script>

<style>
html, body, #app {
  padding: 0;
  margin: 0;
}

#app {
  width: 100%;
}

</style>
