import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from './modules/snackbar'
import layout from './modules/layout'
import info from './modules/info'
import setting from './modules/setting'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    snackbar,
    layout,
    info,
    setting
  },
})
